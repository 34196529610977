import React from 'react';
import styled from "styled-components";
import Image from 'gatsby-image';
import Layout from 'src/components/Layout';
import { Row, Col, ColFlex, RowCol } from 'src/components/Grid';
import { vBlockSize } from 'src/components/GlobalStyle';
import { HeroContainer } from '../components/HeroHome';
import CardIcon from 'src/components/CardIcon';
import { mediaMobileFirstWithBlock } from 'src/helpers/responsive';
import siteConfig from '../../site-config.json';


const Hours = styled.div`
  background-color: #e9e9e9;
  display: flex;
  flex-wrap: wrap;
  ${mediaMobileFirstWithBlock((block, unit) => /* css */`
    padding: ${2 * vBlockSize}px ${2 * block}${unit} ${vBlockSize}px;
  `)}
  p, p:last-child {
    margin-bottom: ${vBlockSize}px;
  }
  div {
    flex: 1 0;
    min-width: 220px;
  }
  h3 {
    margin-bottom: 0;
  }
  em {
    font-size: 32px;
    font-weight: normal;
    font-style: normal;
    color: #000;
  }
`;

const Map = styled.div`
  margin: 0;
  padding: 0;
  a {
    text-decoration: none;
    display: block;
  }
`;

const Img = ({ preview, ...props }) => (
  preview ? (
    <div className="gatsby-image-wrapper">
      <img 
        src={(props.fluid || props.fixed).src}
        alt="Preview"
        style={{ width: '100%', height: 'auto' }} 
      />
    </div>
  ) : (
    <Image {...props} />
  )
);

const OpeningHoursItem = ({ days, hours }) => <>
  <h3>{days}</h3>
  <p dangerouslySetInnerHTML={{ __html: hours }} />
</>;


export const HomePage = ({
  preview, body, bodyTitle,
  title, pageTitleFull, intro, services,
  heroImage, bodyImage, mapImage,
  address = siteConfig.address, openingHours = siteConfig.openingHours,
  siteTitle = siteConfig.siteTitle, mapLink = siteConfig.mapLink,
  phoneNumber = siteConfig.phoneNumber, mail = siteConfig.mail
}) => (

  <>
    <HeroContainer>
      <Row>
        <ColFlex>
          <p className="h2">{intro}</p>
        </ColFlex>
        <Col s0={4} s1={3} s2={3}>
          <Img
            preview={preview}
            fluid={{ 
              ...heroImage.childImageSharp.fluid,
              sizes: '(max-width: 989px) 200px, 320px',
            }}
            loading="eager"
            placeholderClassName="svg-placeholder"
            alt="Pharmacy"
          />
        </Col>
      </Row>
    </HeroContainer>

    <section>
      <RowCol>
        <h2>{bodyTitle}</h2>
      </RowCol>
      <Row>
        {body && body.key === 'body' ? (
          <ColFlex>
            {body}
          </ColFlex>
        ) : (
          <ColFlex dangerouslySetInnerHTML={{ __html: body }} />
        )}
        <Col s1={2}>
          <Img
            preview={preview}
            fluid={{ 
              ...bodyImage.childImageSharp.fluid,
              sizes: '(max-width: 989px) 200px, 320px',
            }}
            placeholderClassName="svg-placeholder"
            alt="Pharmacy"
            style={{ boxShadow: '0 10px 40px #bbb' }}
          /> 
        </Col>
      </Row>
    </section>
    
    {services && (<section>
      <RowCol>
        <h2>{services.title}</h2>
      </RowCol>
      <Row mbb={1} as="ul">
        {services.items.map(({ title, description, icon, link, color }, i) => (
          <Col s1={2} s2={3} key={i} as="li">
            <CardIcon
              {...{ title, description, link, color }}
              icon={preview
                ? icon
                : { svg: icon.childMarkdownRemark.html.substring(3, icon.childMarkdownRemark.html.length - 4) }
              }
              // ratio={1.6}
              height="170px"
            />
          </Col>
        ))}
      </Row>
    </section>)}

    <Row>
      <Col s2={2} as="section">
        <h2 style={{ marginBottom: `${2 * vBlockSize}px` }}>Opening hours</h2>
          <Hours bgColor="#e9e9e9" style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div>
              {openingHours.length > 0 && (
                <OpeningHoursItem {...openingHours[0]} />
              )}
              {openingHours.length > 2 && (
                <OpeningHoursItem {...openingHours[1]} />
              )}
            </div>
            <div>
              {openingHours.length > 2 ? (
                <OpeningHoursItem {...openingHours[2]} />
              ) : (
                (openingHours.length === 2) && <OpeningHoursItem {...openingHours[1]} />
              )}
              {openingHours.length === 4 && (
                <OpeningHoursItem {...openingHours[3]} />
              )}
            </div>
          </Hours>
        </Col>
        <ColFlex as="section">
          <h2 style={{ marginBottom: `${2 * vBlockSize}px` }} title="Address">Location</h2>
          <Map>
            <a href={mapLink} title={`${title} - ${address}`}>
              <Img
                preview={preview}
                fluid={{
                  ...mapImage.childImageSharp.fluid,
                  sizes: '(max-width: 375px) 320x, (min-width: 376px) and (max-width: 989px) 680px, 440px'
                }}
                alt={`Map (${address})`}
              />
            </a>
          </Map>
      </ColFlex>
    </Row>

    {/* <Row>
      {services.items.map(({ title, image, description, icon }) => (
        <Col s0={2} s1={3} key={title}>
          <Img
            fluid={{ ...image.childImageSharp.fluid, sizes: '(max-width: 375px) 150px, (min-width: 376px) and (max-width: 989px) 200px, 200px'}}
            alt={title}
            style={{ marginBottom: `${vBlockSize}px` }}
          />
          <h3>{title}</h3>
          <p>{description}</p>
        </Col>
      ))}
    </Row> */}
    {/* <div dangerouslySetInnerHTML={{ __html: about.childMarkdownRemark.html }} /> */}

  </>
);

const Index = ({ data: { 
  markdownRemark: { html, frontmatter },
  home: { frontmatter: { heroImage } }
} }) => (
  <HomePage body={html} {...frontmatter} heroImage={heroImage} />
);

export default Index;

// export const query = graphql`
//   query HomeQuery($glob: String = "**/home.md") {
//     markdownRemark(fileAbsolutePath: {glob: $glob}) {
//       html
//       frontmatter {
//         pageTitleFull
//         title
//         intro
//         bodyTitle
//         mapLink
//         address
//         heroImage {
//           childImageSharp {
//             fluid(maxWidth: 400, quality: 80) {
//               ...GatsbyImageSharpFluid_withWebp_tracedSVG,
//             }
//           }
//         }
//         bodyImage {
//           childImageSharp {
//             fluid(maxWidth: 400, quality: 80) {
//               ...GatsbyImageSharpFluid_withWebp_tracedSVG,
//             }
//           }
//         }
//         mapImage {
//           childImageSharp {
//             fluid(maxWidth: 440, quality: 80) {
//               ...GatsbyImageSharpFluid_withWebp
//             }
//           }
//         }
//         services {
//           title
//           description
//           items {
//             title
//             image {
//               childImageSharp {
//                 fluid(maxWidth: 300, quality: 80) {
//                   ...GatsbyImageSharpFluid_withWebp
//                 }
//               }
//             }
//             icon {
//               childMarkdownRemark {
//                 html
//               }
//             }
//             link
//             color
//             description
//           }
//         }
//       }
//     }
//   }
// `;
