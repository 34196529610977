import React from 'react';
import { graphql } from 'gatsby';

import Index from '../templates/home';

export default Index;

export const query = graphql`
  query HomeQuery {
    home: markdownRemark(fileAbsolutePath: {glob: "**/home.md"}) {
      frontmatter {
        heroImage {
          childImageSharp {
            fluid(maxWidth: 400, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG,
            }
          }
        }
      }
    }
    markdownRemark(fileAbsolutePath: {glob: "**/home-new.md"}) {
      html
      frontmatter {
        pageTitleFull
        title
        intro
        bodyTitle
        heroImage {
          childImageSharp {
            fluid(maxWidth: 400, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG,
            }
          }
        }
        bodyImage {
          childImageSharp {
            fluid(maxWidth: 400, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG,
            }
          }
        }
        mapImage {
          childImageSharp {
            fluid(maxWidth: 440, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        services {
          title
          description
          items {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 300, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            icon {
              childMarkdownRemark {
                html
              }
            }
            link
            color
            description
          }
        }
      }
    }

  }
`;
