import React from 'react';
import styled, { css } from 'styled-components';
import { vBlockSize, colorLightText } from './GlobalStyle';
import { Link } from 'gatsby';
import { navigate } from '@reach/router';

const ImgContainer = styled.div<{ ratio: number; color?: string; link?: boolean; height?: string; }>`
  position: relative;    
  background-color: ${props => props.color || 'transparent'};
  margin-bottom: ${vBlockSize}px;
  cursor: ${props => props.link ? 'pointer' : 'default'};
  .ratio {
    width: 100%;
    padding-bottom: ${props => props.height ? props.height : `${100 / props.ratio}%`};
  }
  ${props => props.link && css`
    img,
    svg {
      opacity: 1;
      /* transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1); */
      transition: all 100ms;
    }
    &:hover img,
    &:hover svg {
      opacity: 0.8;
      /* transform: translate3d(-50%, -50%, 0) scale3d(1.03125, 1.03125, 1); */
    }
  `}
`;

const CardIconContainer = styled.div`
  margin-bottom: ${vBlockSize * 0.5}px;
  img,
  svg {
    position: absolute;
    height: 50%;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  h3 {
    font-weight: bold;
    margin-bottom: ${vBlockSize * 0.5}px;
    font-size: 1rem;
    a {
      text-decoration: none;
    }
  }
  p {
    margin-bottom: ${vBlockSize * 0.5}px;
    color: ${colorLightText};
  }
`;

const WithLink = ({ link, children }) => (
  link ? (
    <Link to={link}>{children}</Link> 
  ) : (
    children || null
  )
)

const CardIcon = ({ title, description, icon, link = '', color = '#ccc', ratio = 1, height = '',  actionText = 'Learn more' }) => (
  <CardIconContainer color={color}>
    <ImgContainer
      ratio={height ? 0 : ratio}
      height={height}
      link={!!link}
      style={color ? { backgroundColor: color } : null}
      onClick={() => link && navigate(link)}
    >
      <div className="ratio" />
      {icon && (
        icon.svg ? (
          <div dangerouslySetInnerHTML={{ __html: icon.svg }} />
        ) : (
          <img src={icon.publicURL} alt={title} draggable="false" />
        )
      )}
    </ImgContainer>
    <h3><WithLink link={link}>{title}</WithLink></h3>
    <p>{description}</p>
    {link && (
      <p><Link to={link} title={title}>{actionText} {'>'}</Link></p>
    )}
  </CardIconContainer>
);

export default CardIcon;
