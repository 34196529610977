import styled from "styled-components";
import { ColFlex } from 'src/components/Grid';
import { vBlockSize } from 'src/components/GlobalStyle';
import { mediaDesktopFirst } from 'src/helpers/responsive';

export const HeroContainer = styled.section`
  overflow: hidden;
  padding: ${vBlockSize * 2}px 0 ${vBlockSize}px;
  margin-bottom: ${2 * vBlockSize}px;
  background: #cbd0d3;
  /* background: #dce1e5; */
  filter: brightness(1.05) contrast(1.075);
  .gatsby-image-wrapper {
    /* filter: brightness(1.05) contrast(1.075); */
    transform: scale3d(1.14, 1.14, 1);
    ${mediaDesktopFirst(``, ``, `
      width: 200px;
      position: absolute;
      margin-left: -20px;
    `)}
    .svg-placeholder {
      filter: contrast(0.5);
    }
  }
  ${ColFlex} {
    display: flex;
    flex-direction: row;
    align-items: center;
    .h2 {
      z-index: 1;
      margin-bottom: ${2 * vBlockSize}px;
      ${mediaDesktopFirst(`
      `, `
        margin-bottom: ${vBlockSize}px;
      `, `
        font-size: 24px;
      `)}
    }
  }
  img {
    pointer-events: none;
  }
`;

